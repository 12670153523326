import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню | Меню таверни "Fusion"
			</title>
			<meta name={"description"} content={"Насолоджуйтесь ароматами наших шинкарських історій"} />
			<meta property={"og:title"} content={"Меню | Меню таверни \"Fusion\""} />
			<meta property={"og:description"} content={"Насолоджуйтесь ароматами наших шинкарських історій"} />
			<meta property={"og:image"} content={"https://virolosist.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://virolosist.live/media/281620.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://virolosist.live/media/281620.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://virolosist.live/media/281620.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://virolosist.live/media/281620.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://virolosist.live/media/281620.png"} />
			<meta name={"msapplication-TileImage"} content={"https://virolosist.live/media/281620.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="Price-17">
			<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
				Зазирніть у наш гастрономічний гримуар
			</Text>
			<Text margin="0px 50% 20px 0px" font="normal 900 48px/1.2 --fontFamily-serifGaramond" lg-margin="0px 0 20px 0px">
				Меню
			</Text>
			<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="--dark">
				У таверні "Fusion" кожна страва та напій - це історія, яка чекає на свою розповідь. Ось попередній перегляд розділів, які ми пропонуємо:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://virolosist.live/media/m1.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Крильця, настояні на елі
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Замариновані в нашому фірмовому пиві, ці крильця - це танець смаків, який ідеально підходить для початку вашої шинкарської історії.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://virolosist.live/media/m2.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Фрикадельки менестреля
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Соковиті фрикадельки, зліплені вручну, тушковані в насиченому пікантному соусі, що нагадує ситні застілля давніх часів.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://virolosist.live/media/m3.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Бардів бургер
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Міцна, сформована вручну котлета з ремісничим сиром та начинками в стилі таверни, загорнута в підсмажену булочку-бріош.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://virolosist.live/media/m4.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Овочеве обгортання Druid's Delight
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Корисне, яскраве обгортання, наповнене свіжими сезонними овочами, полите пікантною заправкою.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://virolosist.live/media/m5.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Tales of Ale
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						постійна добірка місцевих та міжнародних сортів крафтового пива, кожен з яких має свою унікальну історію.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://virolosist.live/media/m6.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Віскі Wizard's Whiskeys
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Асортимент вишуканих віскі, що ідеально підходять для потягу та розповіді.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});